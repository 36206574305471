import { Button, CalendarIcon, HandIcon, HomeIcon, Icon, IconButton, Link, MapIcon, MenuIcon, Pane, Paragraph, PeopleIcon, SideSheet, TimeIcon, Tooltip } from "evergreen-ui";
import React, { useState } from "react";

export const Header: React.FC<{ isScrolled: boolean }> = ({ isScrolled }) => {
    const [isShown, setIsShown] = useState(false)

    const handleScroll = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <React.Fragment>
            <Pane
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="8%"
                display="flex"
                justifyContent="start"
                alignItems="center"
                className="content-pc"
            >
                <Pane className="hide-sidebar header" position="relative" alignItems="center" height="100%" justifyContent="center" gap={50} width="100%"
                    style={{
                        backgroundColor: isScrolled ? "#d97706" : "transparent",
                        boxShadow: isScrolled ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none",
                    }}
                >
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("event")} padding={6}
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none"
                        }}
                    >
                        O Evento
                    </Link>
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("speakers")}
                        padding={6}
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none"
                        }}
                        marginRight={8}
                    >
                        Palestrantes
                    </Link>
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("schedule")}
                        padding={6}
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none"
                        }}
                    >
                        Programação
                    </Link>
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("localization")}
                        padding={6}
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none"
                        }}
                    >
                        Localização
                    </Link>
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("sponsors")}
                        padding={6}
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none"
                        }}
                    >
                        Realização
                    </Link>
                    <Link
                        href="https://www.sympla.com.br/evento/festival-de-inovacao-e-negocios-de-garanhuns-fing/2721170"
                        padding={4}
                        paddingX={24}
                        style={{
                            color: "#f8fafc",
                            background: "#0d9488",
                            fontWeight: "bold",
                            textDecoration: "none"
                        }}
                        marginLeft={80}>
                        Faça Sua Inscrição
                    </Link>
                </Pane>
            </Pane>

            <Pane
                position="fixed"
                bottom={0}
                left={0}
                width="100%"
                height={70}
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex={1000}
                className="gap-5 bg-slate-900 content-mobile"
            >
                <div
                    onClick={() => handleScroll("localization1")}
                    style={{ textDecoration: 'none', color: '#f8fafc' }}
                    className="flex flex-col items-center justify-center"
                >
                    <Icon icon={MapIcon} color="white" className="mb-1" />
                    <span style={{ fontSize: "10px" }} className="font-bold">Localização</span>
                </div>

                <div
                    onClick={() => handleScroll("sponsors1")}
                    style={{ textDecoration: 'none', color: '#f8fafc' }}
                    className="flex flex-col items-center justify-center"
                >
                    <Icon icon={HandIcon} color="white" className="mb-1" />
                    <span style={{ fontSize: "10px" }} className="font-bold">Patrocin.:</span>
                </div>

                <div
                    onClick={() => handleScroll("event1")}
                    style={{ textDecoration: 'none', color: '#f8fafc' }}
                    className="flex flex-col items-center justify-center -translate-y-4 bg-white border p-4 w-14 h-14 rounded-full"
                >
                    <Icon icon={CalendarIcon} color="black" className="mb-1" />
                    <span style={{ fontSize: "10px" }} className="font-bold text-black">Evento</span>
                </div>

                <div
                    onClick={() => handleScroll("schedule1")}
                    style={{ textDecoration: 'none', color: '#f8fafc' }}
                    className="flex flex-col items-center justify-center"
                >
                    <Icon icon={TimeIcon} color="white" className="mb-1" />
                    <span style={{ fontSize: "10px" }} className="font-bold">Programação</span>
                </div>

                <div
                    onClick={() => handleScroll("speakers1")}
                    style={{ textDecoration: 'none', color: '#f8fafc' }}
                    className="flex flex-col items-center justify-center"
                >
                    <Icon icon={PeopleIcon} color="white" className="mb-1" />
                    <span style={{ fontSize: "10px" }} className="font-bold">Palestrantes</span>
                </div>
            </Pane>
        </React.Fragment>
    );
};
