/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Heading, Pane, Text, Dialog, Tablist, Tab, Link, Avatar } from "evergreen-ui";
import { useSpeakersHook } from "../hooks/useSpeakersHook";
import triangle1 from "../../assets/triangulo superior.png";
import triangle2 from "../../assets/triangulo superior rotado.png";
import waves from "../../assets/onda retangulo.png";

export const Speakers: React.FC = () => {
    const {
        speakers,
        handleSpeakerClick,
        selecteSpeaker, 
        setSelecteSpeaker
    } = useSpeakersHook();

    return (
        <React.Fragment>
            <Pane
                id="speakers"
                height="100vh"
                minHeight="120vh"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                background="white"
                className="snap-center overflow-hidden relative content-pc"
                padding={12}
            >
                <img
                    src={triangle1}
                    alt="Fingapp Festival"
                    className="absolute -bottom-10 w-60"
                    style={{
                        right: "-10px",
                    }}
                />

                <img
                    src={triangle2}
                    alt="Fingapp Festival"
                    className="absolute -top-10 w-60"
                    style={{
                        left: "-10px",
                    }}
                />

                <img
                    src={waves}
                    alt="Fingapp Festival"

                    className="absolute -top-10 w-80"
                    style={{
                        left: "-100px",
                        minHeight: "200vh"
                    }}
                />

                <Heading zIndex={10} marginTop={24} textAlign="start" className="border-b" paddingY={8} size={800} width="56%" marginBottom={12}>Nossos Palestrantes</Heading>

                {/* Grid de cards */}
                <Pane
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    gap={24}
                    maxWidth="80%"
                    padding={8}
                    style={{
                        scrollbarWidth: "none"
                    }}
                    className="overflow-y-auto"
                >
                    {speakers.map((speaker) => (
                        <Pane
                            key={speaker.id}
                            elevation={1}
                            padding={8}
                            borderRadius={8}
                            background="#e5e7eb"
                            //onClick={() => openModal(speaker)}
                            cursor="pointer"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            width="230px"
                            height="230px"
                            className="relative mb-2"
                        >
                            {speaker.image ? (
                                <img
                                    src={speaker.image}
                                    alt={speaker.name}
                                    style={{ width: '100%', height: "100%", borderRadius: 8, objectFit: "cover" }}
                                />
                            ) : (
                                <Avatar
                                    name={speaker.name} // Mostra o nome no avatar
                                    size={100} // Tamanho do avatar
                                    marginBottom={50} // Espaçamento inferior
                                />
                            )}
                            <Heading
                                size={600}
                                borderRadius={3}
                                width="95%"
                                height="35%"
                                padding="8px"
                                textAlign="start"
                                color="white"
                                display="flex"
                                flexDirection="column"
                                bottom={-16}
                                right={-12}
                                background="#0d9488"
                                className="absolute"
                            >
                                <h1 style={{ fontSize: "14px", height: "25%" }}>{speaker.name}</h1>
                                <h1 color="#1e293b" className="text-slate-800" style={{ fontSize: "10px" }}>{speaker.informacoes[1].value}</h1>
                                <span className="text-xs mt-1 text-slate-50">{speaker.informacoes[0].value}</span>
                            </Heading>
                        </Pane>
                    ))}
                </Pane>
            </Pane>

            <Pane
                id="speakers1"
                height="100vh"
                minHeight="120vh"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                className="snap-center bg-gradient-to-r from-slate-800 to-slate-900 overflow-hidden relative content-mobile"
                padding={8}
            >
                <h1
                    className="flex flex-col mt-8 mb-8"
                    style={{
                        width: "80%"
                    }}
                >
                    <p className="p-2 bg-white text-slate-900 font-bold text-3xl">
                        Nossos Palestrantes
                    </p>
                    <Text size={400} color="white" marginTop={16}>
                        Conheça abaixo alguns de nossos convidados especiais.
                    </Text>
                </h1>

                {/* Overlay */}
                {selecteSpeaker && (
                    <Pane
                        position="fixed"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        background="rgba(0, 0, 0, 0.8)"
                        zIndex={1}
                        onClick={() => setSelecteSpeaker(null)}
                    />
                )}

                {/* Grid de cards */}
                <Pane
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    gap={24}
                    maxWidth="100%"
                    maxHeight="75%"
                    style={{
                        scrollbarWidth: "none"
                    }}
                    className="overflow-y-auto"
                >
                    {speakers.map((speaker) => (
                        <Pane
                            key={speaker.id}
                            elevation={selecteSpeaker === speaker.id ? 3 : 1}
                            padding={selecteSpeaker === speaker.id ? 4 : 4}
                            background="#e5e7eb"
                            cursor="pointer"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            width={selecteSpeaker === speaker.id ? "300px" : "150px"}
                            height={selecteSpeaker === speaker.id ? "300px" : "150px"}
                            className={`relative mb-2 ${selecteSpeaker === speaker.id ? "rounded-full" : "rounded-full"}`}
                            style={{
                                position: selecteSpeaker === speaker.id ? "fixed" : "relative",
                                top: selecteSpeaker === speaker.id ? "50%" : "auto",
                                left: selecteSpeaker === speaker.id ? "50%" : "auto",
                                transform: selecteSpeaker === speaker.id ? "translate(-50%, -50%)" : "none",
                                zIndex: selecteSpeaker === speaker.id ? 2 : 0,
                                transition: "all 0.2s ease-in-out",
                            }}
                            onClick={() => handleSpeakerClick(speaker)}
                        >
                            {speaker.image ? (
                                <img
                                    src={speaker.image}
                                    alt={speaker.name}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            ) : (
                                <Avatar
                                    name={speaker.name}
                                    size={100}
                                />
                            )}
                            <Heading
                                size={600}
                                borderRadius={3}
                                width="95%"
                                height={selecteSpeaker ? "45%" : "35%"}
                                padding="8px"
                                textAlign={selecteSpeaker ? "center" : "center" }
                                justifyContent={selecteSpeaker ? "center" : "center"}
                                color="white"
                                display="flex"
                                flexDirection="column"
                                bottom={-16}
                                right={-12}
                                background="#0d9488"
                                className="absolute overflow-hidden"
                                style={{ cursor: "pointer" }}
                            >
                                <h1 
                                    style={{ 
                                        fontSize: selecteSpeaker === speaker.id ? "22px" : "15px" ,
                                        fontWeight: "bold",
                                        height: selecteSpeaker === speaker.id ? "25%" : "100%",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: selecteSpeaker === speaker.id ? "start" : "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {speaker.name}
                                </h1>
                                {selecteSpeaker && selecteSpeaker === speaker.id && (
                                    <div className="text-slate-800 bg-white p-2 mt-2">
                                        <h1 color="#1e293b" className="text-white text-center bg-slate-900 p-1.5 rounded-full" style={{ fontSize: "15px" }}>
                                            {speaker.informacoes[1].value}
                                        </h1>
                                        <span className="text-sm mt-1 text-center">
                                            {speaker.informacoes[0].value}
                                        </span>
                                    </div>
                                )}
                            </Heading>
                        </Pane>
                    ))}
                </Pane>

            </Pane>
        </React.Fragment>
    );
};
