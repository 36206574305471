import { Heading, Link, Pane, Text } from "evergreen-ui";
import React from "react";

export const Localization: React.FC = () => (
    <React.Fragment>
        <Pane
            id="localization"
            height="140vh"
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            background="white"
            className="snap-center relative loc content-pc"
        >
            <Pane height="75%" overflow="hidden" className="loc-info">
                <h1 className="text1">Localização do Evento</h1>
                <h2 className="text2">Centro Cultural Sesc Garanhuns</h2>
                <span className="text3">R. Cônego Benígno Líra - Centro, Garanhuns - PE, 55290-000</span>
                <Pane marginTop={16} className="border-b">{""}</Pane>
                <Pane display="flex" flexDirection="column" alignItems="start" justifyContent="space-around" marginTop={16} className="bg-slate-900 rounded-md loc-info-card1">
                    <h1 color="white" className="card-loc-text1" >Participe Agora Mesmo! Vagas Limitadas</h1>
                    <Link
                        href="https://www.sympla.com.br/evento/festival-de-inovacao-e-negocios-de-garanhuns-fing/2721170"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding={6}
                        paddingX={8}
                        style={{
                            color: "#f8fafc",
                            background: "#FF6F00",
                            fontWeight: "bold",
                            textDecoration: "none",
                            marginTop: "10px",
                        }}
                        width="50%"
                    >
                        Faça Sua Inscrição
                    </Link>
                </Pane>
            </Pane>

            {/* Embed Google Maps */}
            <Pane overflow="hidden" borderRadius={8} boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)" className="rounded-3xl loc-info-map">
                <iframe
                    title="Localização do Evento no Google Maps"
                    src="https://www.google.com.br/maps/embed?pb=!1m18!1m12!1m3!1d3168.352040024759!2d-36.4928824!3d-8.8912978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7070d3af2083a3f%3A0x47466ae3f428ff25!2sCentro%20Cultural%20Sesc%20Garanhuns!5e0!3m2!1spt-BR!2sbr!4v169850128034"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />
            </Pane>
        </Pane>

        <Pane
            id="localization1"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            background="white"
            flexDirection="column"
            className="snap-center relative loc p-8 content-mobile"
        >
            <Pane width="100%" overflow="hidden">
                <h1 className="text1 text-title-sm">Localização do Evento</h1>
                <h2 className="text2 text-title-xsm">Centro Cultural Sesc Garanhuns</h2>
                <span className="text3 text-title-xsms">R. Cônego Benígno Líra - Centro, Garanhuns - PE, 55290-000</span>
                <Pane marginTop={16} className="border-b">{""}</Pane>
                <Pane display="flex" flexDirection="column" alignItems="start" justifyContent="space-around" marginTop={16} className="bg-slate-900 rounded-md p-4">
                    <h1 color="white" className="flex flex-col text-white text-lg mb-2" >
                        Participe Agora Mesmo!
                        <span className="text-sm mt-1 text-slate-400">*Vagas Limitadas</span>
                    </h1>
                    <Link
                        href="https://www.sympla.com.br/evento/festival-de-inovacao-e-negocios-de-garanhuns-fing/2721170"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding={6}
                        paddingX={8}
                        style={{
                            color: "#f8fafc",
                            background: "#FF6F00",
                            fontWeight: "bold",
                            textDecoration: "none",
                            marginTop: "10px",
                        }}
                        width="100%"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Faça Sua Inscrição
                    </Link>
                </Pane>


            </Pane>

            <Pane width="100%" height="40%" overflow="hidden" borderRadius={8} className="shadow-md rounded-3xl mt-8">
                <iframe
                    title="Localização do Evento no Google Maps"
                    src="https://www.google.com.br/maps/embed?pb=!1m18!1m12!1m3!1d3168.352040024759!2d-36.4928824!3d-8.8912978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7070d3af2083a3f%3A0x47466ae3f428ff25!2sCentro%20Cultural%20Sesc%20Garanhuns!5e0!3m2!1spt-BR!2sbr!4v169850128034"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />
            </Pane>
        </Pane>
    </React.Fragment>
);
