import { useEffect, useRef, useState } from "react";

export const useHookHome = () => {
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isShown, setIsShown] = useState(false)

    const scrollToTop = () => {
        containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleScroll = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const scrollPosition = containerRef.current.scrollTop;
                setIsScrolled(scrollPosition > 0);
                setShowScrollTopButton(scrollPosition > window.innerHeight * 0.5);
            }
        };

        const currentContainer = containerRef.current;
        if (currentContainer) {
            currentContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (currentContainer) {
                currentContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return {
        showScrollTopButton,
        isScrolled,
        setIsScrolled,
        scrollToTop,
        containerRef,
        isShown,
        handleScroll,
        setIsShown
    }
}