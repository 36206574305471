// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID as string;

if (!GA_MEASUREMENT_ID) {
  console.error("Google Analytics Measurement ID is missing in the environment variables!");
} else {
  ReactGA.initialize(GA_MEASUREMENT_ID);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// Log de performance do app
reportWebVitals();
