import { useState } from "react";
import Gabrielle from "../../assets/Gabrielle Amorim.jpeg";
import Jaime from "../../assets/Jaime Alheiros.jpeg";
import Pamela from "../../assets/Pâmela Dias.jpeg";
import Rodrigo from "../../assets/Rodrigo Cunha.jpeg";
import Fransisco from "../../assets/WhatsApp Image 2024-11-13 at 10.26.26.jpeg";
import Emerson from "../../assets/Emerson Lima.jpeg";
import Miguel from "../../assets/Miguel Gaia.jpeg";
import Augusto from "../../assets/Augusto Galvão.jpeg";
import Leandro from "../../assets/Leonardo Leandro.jpeg";
import Andrea from "../../assets/Andrea  Gomes.jpeg";
import Lucio from "../../assets/Lúcio Ribeiro.jpeg";
import Yves from "../../assets/Yves Nogueira.jpeg";
import Joao from "../../assets/João Paulo Zica Fialho.jpeg";
import Gabriel from "../../assets/Gabriel Henriques.jpeg";
import Paula from "../../assets/Paula Lourenço.jpeg";
import Mayara from "../../assets/Mayara Araújo.jpeg";
import Teresa from "../../assets/Teresa Maciel.jpeg";
import Caroline from "../../assets/Caroline Carneiro.jpeg";
import Jana from "../../assets/Jana.jpeg";
import Banda from "../../assets/Banda de Pífano Folclore Verde do Castainho.jpeg";
import DJ from "../../assets/DJ Synesthezk.jpeg";

export const useSpeakersHook = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selecteSpeaker, setSelecteSpeaker] = useState(null);

    const handleSpeakerClick = (speaker: any) => {
        setSelecteSpeaker(selecteSpeaker === speaker.id ? null : speaker.id);
    };

    const speakers = [
        {
            id: 1,
            name: "Emerson Lima",
            informacoes: [
                { label: "Empresa", value: "Sauter" },
                { label: "Cargo", value: "Fundador e CEO" }
            ],
            image: Emerson
        },
        {
            id: 2,
            name: "Miguel Gaia",
            informacoes: [
                { label: "Empresa", value: "Governo de Pernambuco" },
                { label: "Cargo", value: "Secretário executivo de Transformação Digital." }
            ],
            image: Miguel
        },
        {
            id: 3,
            name: "Paula Lourenço",
            informacoes: [
                { label: "Empresa", value: "SESC Pernambuco" },
                { label: "Cargo", value: "Diretora de Programas Sociais" }
            ],
            image: Paula
        },
        {
            id: 4,
            name: "Augusto Galvão",
            informacoes: [
                { label: "Empresa", value: "CESAR" },
                { label: "Cargo", value: "Diretor Executivo" }
            ],
            image: Augusto
        },
        {
            id: 5,
            name: "Leonardo Leandro",
            informacoes: [
                { label: "Empresa", value: "Livelo" },
                { label: "Cargo", value: "Technical Product Manager" }
            ],
            image: Leandro
        },
        {
            id: 6,
            name: "Francisco Dias",
            informacoes: [
                { label: "Empresa", value: "Grupo Moura" },
                { label: "Cargo", value: "Gerente Geral de TI" }
            ],
            image: Fransisco
        },
        {
            id: 7,
            name: "Jaime Alheiros",
            informacoes: [
                { label: "Empresa", value: "SESI Pernambuco" },
                { label: "Cargo", value: "Coord de Transformação Digital" }
            ],
            image: Jaime
        },
        {
            id: 8,
            name: "Rodrigo Cunha",
            informacoes: [
                { label: "Empresa", value: "Neurotech" },
                { label: "Cargo", value: "Chief Product Officer e Sócio Fundador" }
            ],
            image: Rodrigo
        },
        {
            id: 9,
            name: "Andrea Gomes",
            informacoes: [
                { label: "Empresa", value: "Maya Labs" },
                { label: "Cargo", value: "Consultora de Impacto" }
            ],
            image: Andrea
        },
        {
            id: 10,
            name: "Lúcio Ribeiro",
            informacoes: [
                { label: "Empresa", value: "Porto Digital" },
                { label: "Cargo", value: "Empreendedor Residente" }
            ],
            image: Lucio
        },
        {
            id: 11,
            name: "Yves Nogueira",
            informacoes: [
                { label: "Empresa", value: "Porto Digital" },
                { label: "Cargo", value: "CSO da di2win, Softex PE e Conselheiro" }
            ],
            image: Yves
        },
        {
            id: 12,
            name: "João Paulo Zica Fialho",
            informacoes: [
                { label: "Empresa", value: "RAJA" },
                { label: "Cargo", value: "CEO" }
            ],
            image: Joao
        },
        {
            id: 13,
            name: "Gabrielle Amorim",
            informacoes: [
                { label: "Empresa", value: "iFood" },
                { label: "Cargo", value: "Engenheira de Software" }
            ],
            image: Gabrielle
        },
        {
            id: 14,
            name: "Pâmela Dias",
            informacoes: [
                { label: "Empresa", value: "Armazém da Criatividade" },
                { label: "Cargo", value: "Gestora de Inovação" }
            ],
            image: Pamela
        },
        {
            id: 15,
            name: "Gabriel Henriques",
            informacoes: [
                { label: "Empresa", value: "We Grow" },
                { label: "Cargo", value: "Sócio" }
            ],
            image: Gabriel
        },
        {
            id: 16,
            name: "Mayara Araújo",
            informacoes: [
                { label: "Empresa", value: "Salesforce" },
                { label: "Cargo", value: "Technical Consultant" }
            ],
            image: Mayara
        },
        {
            id: 17,
            name: "Teresa Maciel",
            informacoes: [
                { label: "Empresa", value: "SECTI-PE" },
                { label: "Cargo", value: "Diretora de Avanço Tecnológico" }
            ],
            image: Teresa
        },
        {
            id: 18,
            name: "Caroline Carneiro",
            informacoes: [
                { label: "Empresa", value: "Agência de Desenv. Econômico - PE" },
                { label: "Cargo", value: "Coordenadora da ADEPE" }
            ],
            image: Caroline
        },
        {
            id: 19,
            name: "Jana Branco",
            informacoes: [
                { label: "Empresa", value: "CESAR" },
                { label: "Cargo", value: "Gerente de Soluções Educacionais e de Inovação" }
            ],
            image: Jana
        },
        {
            id: 20,
            name: "Eduardo Espinhara",
            informacoes: [
                { label: "Empresa", value: "SESC Arcoverde" },
                { label: "Cargo", value: "Supervisor de Cultura" }
            ],
            image: null
        },
        {
            id: 21,
            name: "Cecilia Jucá",
            informacoes: [
                { label: "Empresa", value: "ABInbev" },
                { label: "Cargo", value: "Gerente Global de Inovação & AI" }
            ],
            image: null
        },
        {
            id: 22,
            name: "Alexandre Marinho",
            informacoes: [
                { label: "Empresa", value: "Prefeitura Municipal de Garanhuns" },
                { label: "Cargo", value: "Secretário de Planejamento e Orçamento" }
            ],
            image: null
        },
        {
            id: 23,
            name: "Gilson Alencar",
            informacoes: [
                { label: "Empresa", value: "Mondelez" },
                { label: "Cargo", value: "Gerente de Planejamento" }
            ],
            image: null
        },
        {
            id: 24,
            name: "Silvio Vital",
            informacoes: [
                { label: "Empresa", value: "Porto Digital" },
                { label: "Cargo", value: "Coordenador de Inovação Aberta" }
            ],
            image: null
        },
        {
            id: 25,
            name: "Zeca Branco",
            informacoes: [
                { label: "Empresa", value: "Sauter" },
                { label: "Cargo", value: "Diretor da Sauter University" }
            ],
            image: null
        },
        {
            id: 26,
            name: "José Farias",
            informacoes: [
                { label: "Empresa", value: "Clandestinø" },
                { label: "Cargo", value: "Fundador do Clandestinø Café" }
            ],
            image: null
        },
        {
            id: 27,
            name: "Eiran Simis",
            informacoes: [
                { label: "Empresa", value: "CESAR" },
                { label: "Cargo", value: "Consultor de Empreendedorismo" }
            ],
            image: null
        },
        {
            id: 28,
            name: "Luana Gonçalves",
            informacoes: [
                { label: "Empresa", value: "Sauter" },
                { label: "Cargo", value: "Diretora de Pessoas e Cultura" }
            ],
            image: null
        },
        {
            id: 29,
            name: "Ana Uriarte",
            informacoes: [
                { label: "Empresa", value: "Quark" },
                { label: "Cargo", value: "Co Founder" }
            ],
            image: null
        },
        {
            id: 30,
            name: "Pettrus Nascimento",
            informacoes: [
                { label: "Empresa", value: "Prol Educa" },
                { label: "Cargo", value: "CEO" }
            ],
            image: null
        },
        {
            id: 31,
            name: "Rafael Soares",
            informacoes: [
                { label: "Empresa", value: "Comeia" },
                { label: "Cargo", value: "CEO" }
            ],
            image: null
        },
        {
            id: 32,
            name: "Leandro Teixeira",
            informacoes: [
                { label: "Empresa", value: "Hub de Inovação" },
                { label: "Cargo", value: "Coordenador do Hub de Inovação Garanhuns" }
            ],
            image: null
        },
        {
            id: 33,
            name: "Julie Jordão",
            informacoes: [
                { label: "Empresa", value: "Cogtech" },
                { label: "Cargo", value: "Gestora de Negócios" }
            ],
            image: null
        },
        {
            id: 34,
            name: "Maria Digna Mota",
            informacoes: [
                { label: "Empresa", value: "CESAR" },
                { label: "Cargo", value: "Analista de Empreendedorismo" }
            ],
            image: null
        },
        {
            id: 35,
            name: "Banda de Pífano",
            informacoes: [
                { label: "Empresa", value: "Folclore Verde do Castainho" },
                { label: "Cargo", value: "Música e Artes" }
            ],
            image: Banda
        },
        {
            id: 36,
            name: "DJ Synesthezk",
            informacoes: [
                { label: "Empresa", value: "----" },
                { label: "Cargo", value: "Música e Artes" }
            ],
            image: DJ
        },
    ];

    const openModal = (speaker: any) => {
        setSelectedSpeaker(speaker);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedSpeaker(null);
        setSelectedIndex(0);
    };

    return {
        isOpen,
        speakers,
        openModal,
        closeModal,
        selectedSpeaker,
        selectedIndex,
        setSelectedIndex,
        handleSpeakerClick,
        selecteSpeaker, 
        setSelecteSpeaker 
    }

}
