import { useState, useEffect, useRef } from "react";
import { Pane, IconButton, ArrowUpIcon, Tooltip, Icon, HomeIcon } from "evergreen-ui";
import { Header } from "./components/Header";
import { Schedule } from "./components/Schedule";
import { Speakers } from "./components/Speakers";
import { Event } from "./components/Event";
import { Localization } from "./components/Localization";
import { Init } from "./components/Init";
import { Sponsors } from "./components/Sponsors";
import { useHookHome } from "./hooks/useHookHome";

export const Home: React.FC = () => {
    const {
        showScrollTopButton,
        isScrolled,
        scrollToTop,
        containerRef,
        handleScroll
    } = useHookHome();

    return (
        <Pane
            ref={containerRef}  // Adiciona a referência do contêiner
            className="flex-col w-full overflow-y-scroll h-screen snap-y"
            //paddingTop={5}
            style={{
                scrollbarWidth: "none",
                //  position: "relative",
            }}
        >
            <Header isScrolled={isScrolled} />
            <Init />
            <Event />
            <Speakers />
            <Schedule />
            <Localization />
            <Sponsors />

            {/* Botão "Voltar ao Topo" */}
            {showScrollTopButton && (
                <>
                    <Tooltip content="Voltar ao topo" position="left">
                        <IconButton
                            icon={ArrowUpIcon}
                            onClick={scrollToTop}
                            appearance="primary"
                            intent="success"
                            size="large"
                            position="fixed"
                            bottom={24}
                            right={24}
                            zIndex={1000}
                            className="content-pc" />
                    </Tooltip>

                    <div
                        onClick={() => handleScroll("home1")}

                        style={{
                            textDecoration: 'none',
                            color: '#f8fafc',
                            bottom: 80,
                            right: 24,
                            zIndex: 1000,
                            position: "fixed"
                        }}
                        className="flex flex-col items-center justify-center -translate-y-4 bg-white border p-4 w-14 h-14 rounded-full content-mobile"
                    >
                        <Icon icon={HomeIcon} color="black" className="mb-1" />
                        <span style={{ fontSize: "10px" }} className="text-slate-800 font-bold">Inicio</span>
                    </div>
                </>
            )}
        </Pane>
    );
};
