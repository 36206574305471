import { Button, Heading, Pane, Text } from "evergreen-ui";
import Palesta from "../../assets/data-analysis_8662238.png";
import Networking from "../../assets/marketing_4522559.png";
import Exposicao from "../../assets/security_8662357.png";
import Atracoes from "../../assets/drumstick_8873123.png";
import textBackground from "../../assets/texto-background.png";
import triangle2 from "../../assets/triangulo superior rotado.png";
import textCircle from "../../assets/texto bola cabeça.png";
import React, { useState } from "react";

import frente from "../../assets/panfleto programação fing.png"
import verso from "../../assets/panfleto programação fing - verso .png"

export const Schedule: React.FC = () => {
    const [showFront, setShowFront] = useState(true);

    const toggleView = () => {
        setShowFront(!showFront);
    };

    const scheduleDataFront = [
        { time: "09:30", title: "Dinheiro é tech: A evolução da moeda", speaker: "Eiran Simis (CESAR)" },
        { time: "10:05", title: "Alternativas de captação de investimentos", speaker: "Augusto Galvão, João Fialho, Yves Nogueira" },
        { time: "10:50", title: "Investimento em startups", speaker: "João Fialho (Raja)" },
        { time: "11:10", title: "Garanhuenses em grandes empresas de tecnologia", speaker: "Leandro Leonardo, Mayara Araújo, Gabrielle Amorim" },
        { time: "11:55", title: "Como a inteligência artificial pode aumentar a produtividade", speaker: "Rafael Soares (Comeia)" },
        { time: "14:00", title: "Gestão pública eficiente", speaker: "Teresa Maciel, José Farias, Alexandre Marinho" },
        { time: "14:45", title: "Estratégias validadas para aquisição de clientes B2B e B2C", speaker: "Gabriel Henriques (We Grow)" },
        { time: "15:20", title: "Debugando negócios e produtos digitais", speaker: "Lúcio Ribeiro (Empreendedor)" },
        { time: "15:55", title: "Inovação e sustentabilidade", speaker: "Andrea Gomes (Maya Labs)" },
        { time: "16:30", title: "Educação, tecnologia e impacto", speaker: "Ana Uriate, Pettrus Nascimento, Julie Jordão" },
    ];

    const backScheduleData = [
        { time: "08:30", title: "Cerimônia de abertura", speaker: "Banda de Pífano, SEBRAE Pernambuco" },
        { time: "09:30", title: "Hub PE Criativo", speaker: "Paula Lourenço (Sesc PE)" },
        { time: "09:50", title: "Economia criativa e sustentabilidade", speaker: "Pâmela Dias, Matheus Alves" },
        { time: "10:35", title: "Case de impacto: Sauter University", speaker: "Zeca Branco (Sauter)" },
        { time: "10:55", title: "Construindo o impossível", speaker: "Emerson Lima (Sauter)" },
        { time: "11:30", title: "Pessoas e representatividade", speaker: "Luana Gonçalves (Sauter)" },
        { time: "14:00", title: "Inovação e desenvolvimento territorial", speaker: "José Farias (SUDENE)" },
        { time: "14:20", title: "Inovação nas grandes corporações", speaker: "Francisco Dias, Gilson Alencar, Maria Cecilia Jucá" },
        { time: "15:05", title: "Oportunidades no Agreste", speaker: "Teresa Maciel (Secti - Gov PE)" },
        { time: "15:25", title: "Transformação digital com CESAR", speaker: "Jana Branco (CESAR)" },
    ];

    return (
        <React.Fragment>
            <Pane
                id="schedule"
                height="100vh"
                display="flex"
                flexDirection="column" // Torna o layout em coluna
                alignItems="start"
                justifyContent="start"
                className="snap-center relative loc bg-gradient-to-r from-sky-950 to-sky-900 p-8 shadow-2xl overflow-hidden content-pc"
            >
                <Heading size={800} marginBottom={20} color="white">Programação do Evento</Heading>

                {showFront ? (
                    <Pane display="flex" flexDirection="column" width="100%" height="100%" alignItems="start" padding={32}>
                        <Heading size={600} marginBottom={32} padding={8} background="white" color="#122C49" borderRadius={4}>ARENA SAUTER</Heading>
                        <Pane display="flex" flexDirection="row" width="100%" height="100%" justifyContent="space-between">
                            <Pane width="45%">
                                {scheduleDataFront.slice(0, 7).map((item, index) => (
                                    <Pane key={index} marginBottom={12}>
                                        <Text color="white" size={500} fontWeight="bold" background="white" style={{ color: "#122C49" }} className="mr-2 p-0.5 rounded-sm">{item.time}</Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>- </Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>{item.title}</Text>
                                        <Text color="white" size={400} display="block">{item.speaker}</Text>
                                    </Pane>
                                ))}
                            </Pane>
                            <Pane width="45%">
                                {scheduleDataFront.slice(7).map((item, index) => (
                                    <Pane key={index} marginBottom={12}>
                                        <Text color="white" size={500} fontWeight="bold" background="white" style={{ color: "#122C49" }} className="mr-2 p-0.5 rounded-sm">{item.time}</Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>- </Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>{item.title}</Text>
                                        <Text color="white" size={400} display="block">{item.speaker}</Text>
                                    </Pane>
                                ))}
                            </Pane>
                        </Pane>

                        <Pane display="flex" flexDirection="row" width="100%" marginTop={16} className="gap-4">
                            <Button onClick={toggleView} appearance="primary" intent="success" marginRight={24}>
                                Ver Verso
                            </Button>
                            <a href={frente} download="programacao_frente.png">
                                <Button appearance="primary" intent="warning">
                                    Baixar Frente
                                </Button>
                            </a>
                            <a href={verso} download="programacao_verso.png">
                                <Button appearance="primary" intent="danger">
                                    Baixar Verso
                                </Button>
                            </a>
                        </Pane>
                    </Pane>
                ) : (
                    <Pane display="flex" flexDirection="column" alignItems="start" justifyContent="center" width="100%" height="100%" padding={32}>
                        <Heading size={600} marginBottom={32} padding={8} background="white" color="#122C49" borderRadius={4}>PALCO COLINAS</Heading>
                        <Pane display="flex" flexDirection="row" width="100%" height="100%" justifyContent="space-between">
                            <Pane width="45%">
                                {backScheduleData.slice(0, 7).map((item, index) => (
                                    <Pane key={index} marginBottom={12}>
                                        <Text color="white" size={500} fontWeight="bold" background="white" style={{ color: "#122C49" }} className="mr-2 p-0.5 rounded-sm">{item.time}</Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>- </Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>{item.title}</Text>
                                        <Text color="white" size={400} display="block">{item.speaker}</Text>
                                    </Pane>
                                ))}
                            </Pane>
                            <Pane width="45%">
                                {backScheduleData.slice(7).map((item, index) => (
                                    <Pane key={index} marginBottom={12}>
                                        <Text color="white" size={500} fontWeight="bold" background="white" style={{ color: "#122C49" }} className="mr-2 p-0.5 rounded-sm">{item.time}</Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>- </Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>{item.title}</Text>
                                        <Text color="white" size={400} display="block">{item.speaker}</Text>
                                    </Pane>
                                ))}
                            </Pane>
                        </Pane>
                        <Button marginTop={16} onClick={toggleView} appearance="primary" intent="warning">
                            Ver Frente
                        </Button>
                    </Pane>
                )}
            </Pane>

            <Pane
                id="schedule1"
                height="140vh"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                className="snap-center relative loc bg-gradient-to-r from-sky-950 to-sky-900 p-6 shadow-2xl overflow-hidden content-mobile"
            >
                <h1
                    className="flex flex-col mt-16"
                    style={{
                        width: "100%",
                        zIndex: 99
                    }}
                >
                    <p className="p-2 bg-white text-slate-900 font-bold text-3xl">
                        Programação do Evento
                    </p>
                    <h1 className="mt-4 text-white">
                        Confira a programação completa do Fing Festival
                    </h1>
                </h1>

                <img
                    src={triangle2}
                    alt="Fingapp Festival"
                    className="absolute -top-10 w-60 opacity-15"
                    style={{
                        left: "-10px",
                        zIndex: 0
                    }}
                />

                <img
                    src={textCircle}
                    alt="Fingapp Festival"
                    className="absolute bottom-16 opacity-50"
                    style={{
                        left: "-40%",
                        width: "80%",
                        bottom: -50,
                        zIndex: 0
                    }}
                />

                <img
                    src={textBackground}
                    alt="Fingapp Festival"
                    className="absolute w-32 opacity-25 textBackground"
                    style={{
                        right: "-10px",
                        top: "-80px",
                    }}
                />

                {showFront ? (
                    <Pane display="flex" flexDirection="column" width="100%" height="120vh" alignItems="start" zIndex={999}>
                        <Heading size={600} marginBottom={32} marginTop={8} padding={8} background="white" color="#122C49" borderRadius={4}>ARENA SAUTER</Heading>
                        <Pane display="flex" flexDirection="row" width="100%" height="100%" justifyContent="space-between">
                            <Pane
                                width="100%"
                                className="overflow-y-scroll"
                                maxWidth="100%"
                                maxHeight="85%"
                                style={{
                                    scrollbarWidth: "none"
                                }}
                            >
                                {scheduleDataFront.map((item, index) => (
                                    <Pane key={index} marginBottom={24}>
                                        <Text color="white" size={500} fontWeight="bold" background="white" style={{ color: "#122C49" }} className="mr-2 p-0.5 rounded-sm">{item.time}</Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>- </Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>{item.title}</Text>
                                        <Text color="white" size={400} display="block">{item.speaker}</Text>
                                    </Pane>
                                ))}
                                <Pane display="flex" flexDirection="column" width="100%" marginTop={16} className="gap-4 items-center">
                                    <Button onClick={toggleView} appearance="primary" intent="success" width="50%">
                                        Ver Verso
                                    </Button>
                                    <a href={frente} download="programacao_frente.png" style={{ width: "50%" }}>
                                        <Button appearance="primary" intent="warning" width="100%">
                                            Baixar Frente
                                        </Button>
                                    </a>
                                    <a href={verso} download="programacao_verso.png" style={{ width: "50%" }}>
                                        <Button appearance="primary" intent="danger" width="100%">
                                            Baixar Verso
                                        </Button>
                                    </a>
                                </Pane>
                            </Pane>
                        </Pane>
                    </Pane>
                ) : (
                    <Pane display="flex" flexDirection="column" alignItems="start" justifyContent="center" width="100%" height="120vh" zIndex={999}>
                        <Heading size={600} marginBottom={32} marginTop={8} padding={8} background="white" color="#122C49" borderRadius={4}>PALCO COLINAS</Heading>
                        <Pane display="flex" flexDirection="row" width="100%" height="100%" justifyContent="space-between">
                            <Pane
                                width="100%"
                                className="overflow-y-scroll"
                                maxWidth="100%"
                                maxHeight="95%"
                                style={{
                                    scrollbarWidth: "none"
                                }}
                            >
                                {backScheduleData.map((item, index) => (
                                    <Pane key={index} marginBottom={24}>
                                        <Text color="white" size={500} fontWeight="bold" background="white" style={{ color: "#122C49" }} className="mr-2 p-0.5 rounded-sm">{item.time}</Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>- </Text>
                                        <Text color="white" size={500} fontWeight="bold" style={{ color: "#D97706" }}>{item.title}</Text>
                                        <Text color="white" size={400} display="block">{item.speaker}</Text>
                                    </Pane>
                                ))}
                                <Pane display="flex" flexDirection="column" width="100%" marginTop={16} className="gap-4 items-center">
                                    <Button onClick={toggleView} appearance="primary" intent="warning" marginBottom={4} width="50%">
                                        Ver Frente
                                    </Button>
                                </Pane>
                            </Pane>
                        </Pane>
                    </Pane>
                )}

            </Pane>
        </React.Fragment>
    )
};
