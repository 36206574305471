// App.tsx
import { Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./views/Home";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Envie o evento de visualização de página para o Google Analytics sempre que a rota mudar
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <div className="flex items-center justify-center w-screen h-screen overflow-hidden bg-slate-900">
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
